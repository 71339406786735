@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


div.full {
  width: 100%;
}


.logo {
  height: 10em;
  width: auto;
  display: table;
  margin: auto;
}

.customer-name {
  color : #3F51B5;
  text-transform: uppercase;
  font-weight: bolder;
}


.close-icon {
  position : absolute;
  top : .5em;
  right: .5em;
  color: #aaa;
}

.slogan {
  color: #3F51B5;
  font-family: "Pacifico";
}

.site-link {

}

/*a { color : #3F51B5 !important}*/

.mapboxgl-ctrl {
  display: none !important;
}


.country-selector {
  background: url('/public/fondo.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

body {
  width : 100vw;
  overflow-x : hidden;
}

.country-selector-inner {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px) saturate(1.2);
  color : white;
}

.post-logo {
  font-size : 24px;
  margin-left : 2px;
}

.color-white {
  color : #fafafa !important;
}

.color-white a {
  color : white !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
}

.MuiButton-contained {
  color : white !important;
}

.hero-banner {
  height: 93vh;
  box-shadow : 0 0 1em rgba(0, 0, 0, 0.3);
}

.country-selector-title {
  text-transform: uppercase;
  text-shadow: 0 4px 7px rgb(0 0 0);
  font-weight: 600;
}

.customer-banner {
  position: relative;
  width: 100%;
  height: calc(100vh - 50px);
  color: white;
  text-align: center;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}

.customer-banner h1 {
  font-weight: 600;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.9);
}

.customer-banner h3 {
  font-weight: 400;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.9);
}

.customer-banner-slogan {
  position:absolute
}

.table-full {
  display: table;
  width: 100%;
  height: 100%;
}

.table-full-row {
  display: table-row;
  height: 100%;
}

.table-full-cell {
  display : table-cell;
  height: 100%;
}

.align-middle {
  vertical-align: middle;
}

.bold {
  font-family: Montserrat;
  font-weight: 900;
}

.info-icon {
  font-size : 1.2em !important;
  margin-right: .25em;
  color: #666;
  transition : all .33s;
}

.info-icon:hover{
  color: black;
  transition : all .33s;
}

.mapboxgl-popup {
  z-index: 99999999 !important;
  position: fixed !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.mapboxgl-popup-tip {
  display: none !important;
}

.wrap {
  max-width: 100%;
  word-break: break-word;
  padding: .5em;
}

.comma-list:not(:last-child)::after {
  content : ", "
}

.tcenter {
  display: table;
  margin: auto;
  float :none;
}

.MuiSlider-valueLabel {
  opacity: .8
}

.app-logo {
  height: 32px;
  vertical-align: middle;
  position: relative;
}

.google-translate-selector {
  position: fixed;
  width: 100%;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 14px 14px 6px 0.9em rgba(0,0,0, 0.55);
  z-index: 11;
}

.goog-te-banner-frame {
  top : 65px !important;
}

#menu-link {
  width: 100%;
  height: 4em;
  position:absolute;
  top: -4em;
}

.relative {
  position: relative
}

.filters-top {
  z-index: 9999999;
  position: fixed !important;
  top : 2px;
  right : 0;
}

.img-fluid {
  width: 100%;
}

a:hover {
  color : inherit !important;
}

.img-fluid {
  display: table;
  height : 40vh;
  width: auto;
  margin: auto;
  box-shadow : 0 0 .33em #616161;
}

@media screen and (orientation: portrait){
  .img-fluid {
    height: auto;
    width: 100%;
  }
}


.full-pointer {
  display: block;
  background: #fbfbfb;
  border : 1px solid #eaeaea;
  cursor: pointer;
}

h6 {
  margin-bottom : 0;
}

h3 {
  border-bottom: 1px solid #80808042;
  padding-bottom: 1em;
}


.MuiTableCell-root {
  border : none !important;
}


.MuiChip-root {
  background : #fafafa !important;
}

.text-right {
   text-align: right !important;
}

.swiper-slide:nth-child(1n) {
  background-color: #fafafa;
}

.swiper-slide:nth-child(2n) {
  background-color: #fbfbfb;
}


@media screen and (max-width: 1024px) {
  .MuiBox-root {
    padding : .5em !important;
  }
}


th {
  font-weight: bold !important;
}

.customer-banner h3 {
  border : none !important;
}


.bar-logo {
  width: 98%;
  margin: auto;
  box-shadow : none !important;
  display: table;
}


.Aligner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.Aligner-item {
  width: 100%;
}

.Aligner-item--top {
  align-self: flex-start;
}

.Aligner-item--bottom {
  align-self: flex-end;
}


.menu-embed-featured {
  background: rgb(2,0,36);
  background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(15,15,15,1) 35%, rgba(69,69,69,1) 100%);
  height: 100vh;
  color: #e2e2e2;
  z-index: 9999999;
  position: relative;
}



.img-full {
  width: auto;
  height: 55vh;
  max-width : 100%;
  display: table;
  margin: auto;
  aspect-ratio: 1;
  border-radius : .5em;
}

.menu-featured-image {
  height : 55%;
  min-height: 55%;
  padding: 0;
  border-radius : 1em;
  overflow: hidden;
  box-shadow: 0 0 .5em black;
  display: table !important;
  width: 100% !important;
  background-color: rgba(255,255,255, 0.5);
}

.menu-featured-image-bg {
  background-size: cover !important;
  background-position: center center !important;
  height: 55vh !important;
  aspect-ratio : 1;
  display: table !important;
  width: 100% !important;
}

.menu-featured-name {
  height: 15vh;
  min-height: 15vh;
  max-height: 15vh;
  overflow-y: hidden;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-lines: 3;
  display: block;
}

.menu-featured-name h1 {
  line-height: .8em !important;
}

.menu-featured-description {
  height: 3em;
  min-height: 3em;
  max-height: 3em;
  overflow: hidden;
  text-overflow: fade;
  width: 100%;
  display: block;
}



.qr-snippet {
  color: white;
  text-transform: uppercase;
  font-weight: bolder;
  width: 100%;
  text-align: center;
}

.qr-wrap {
    /*position: absolute;*/
    /*bottom : 20vh;*/
    /*right : 1em;*/
}

.qr-wrap svg {
  display: table;
  margin: auto;
  width: 70%;
}

.bar-menu-name {
  font-weight: bolder;
  text-align: center;
  color : white;
  font-size : 2em;
  font-style: italic;
  text-shadow : 1px 1px 1px black;
}


.slick-dots {
  filter: invert(1);
}

.menu-featured-image-bg span {
  position : absolute;
  top: 5%;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  padding: 1em;
  font-weight: bolder;
  transform: skewY(-5deg);
  text-transform: uppercase;
  text-shadow: 0 0 4px black;
}


.MuiCardContent-root {
  width: 100%;
}

@media screen and (min-width: 1280px){

  .bar-logo {
    width: 100%;
    height : auto;
  }

  .bar-menu-name {
    word-wrap: break-word;
    width: 100%;
    min-width: 100%;
  }

  .left-bar {
    height : 100vh;
    max-height : 100vh;
    overflow-y : hidden;
  }

}

@media screen and (max-width: 1280px){

  .menu-featured-image-bg {
    height: 45vh !important;
  }

  .qr-wrap {
    height: 100%;
    float: right !important;
    margin-right: 1em;
  }

  .qr-wrap svg {
    height: 100%;
    width: auto;
  }

  .left-bar {
    padding : 8px;
    height : 10vh;
    max-height : 10vh;
    width: 100%;
    overflow-y : hidden;
    display: flex;

  }

  .bar-menu-name {
    height: 100%;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .bar-menu-name span {

  }

  .left-bar br, .qr-snippet, .left-bar small {
    display: none !important;
  }

  .left-bar * {
    float : left;
  }

  .bar-logo {
    height: 98%;
    width: auto;
  }

  html {
    font-size : 12px;
  }

  .MuiCardContent-root {
    width: 100% !important;
  }

  .MuiDialog-paper {
    width : 100%;
    max-width: 100% !important;
    margin: 0 !important;
  }

  .MuiCardContent-root .MuiCardContent-root {
    padding-left : 4px !important;
    padding-right : 4px !important;
  }

}


.item {
  width: 85%;
  height: 2.8em;
  float: left;
}

.item td {
  vertical-align: middle;
}

.item-name {
  font-size: .8em;
  letter-spacing: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 1em;
  word-break: keep-all !important;
  font-weight: bold;
  float: right;
  text-align: right;
}


.featured-dish-name {
  font-size : 2rem;
}

.menu-featured-name h1 {
  line-height: 1em!important;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
}
